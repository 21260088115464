<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="headerClass">生命科技患者管理平台</div>
</template>
<script>
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Header',
	props: {},
	data() {
		return {};
	}
};
</script>
<style scoped>
.headerClass {
	color: #4870ff;
	text-align: left;
	font-size: 20px;
	line-height: 50px;
	margin-left: 16px;
	font-weight: bolder;
}
</style>
