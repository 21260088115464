<template>
  <div style="margin: 10px;">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-for="item in currentPath" :key="item.path">
        {{ item.meta.title }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Breadcrumb',
  data() {
    return {
      currentPath: null
    };
  },
  watch: {
    $route: {
      handler() {
        this.getBreadList();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getBreadList() {
      //$route.matched 将会是一个包含从上到下的所有对象 (副本)。
      let matched = this.$route.matched.filter(item => item.name !== "Layout");
      this.currentPath = matched;
    }
  }
};
</script>
